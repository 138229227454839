import React from "react";
import Layout from "../other/layout";
import Contact from "../components/AC-StaticPages/Contact";
import SEO from "../other/seo";

export default function contact() {
  return (
    <Layout>
      <SEO title="Contact" />

      <Contact />
    </Layout>
  );
}
